<template>
  <b-carousel
    v-if="landingPage && landingPage.images && landingPage.images.length > 0"
    id="carousel-1"
    v-model="slide"
    :interval="4000"
    controls
    indicators
    background="#ababab"
    img-width="1024"
    img-height="480"
    style="text-shadow: 1px 1px 2px #333"
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
  >
    <b-carousel-slide
      v-for="(image, index) in landingPage.images"
      :key="index"
      :img-src="image"
    >
      <template #img>
        <img
          class="d-block w-100"
          width="1024"
          height="480"
          :src="image"
          alt="image slot"
        />
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      slide: 0,
      sliding: null,
    }
  },
  methods: {
    onSlideEnd(slide) {
      this.sliding = false
    },
    onSlideStart(slide) {
      this.sliding = true
    },
  },
  computed: {
    ...mapGetters("landingPage", ["landingPage"]),
  },
}
</script>
