<template>
  <b-card
    v-if="product && product.product_attributes"
    class="product__card"
    no-body
    text-variant="primary"
    @click="handleStoreProductSelection(product.product_attributes, product)"
  >
    <!-- Product Details - Photo -->
    <div class="product__image__container">
      <b-img
        class="product__image"
        :alt="`${product.product_attributes.name}-${product.id}`"
        fluid
        rounded
        :src="`${product.product_attributes.logo}`"
      />
    </div>

    <!-- Product Details - Name, variant and price -->
    <div class="product__details">
      <b-card-text class="bold mb-0 pb-0 h5">
        {{ product.product_attributes.name }}
      </b-card-text>

      <p class="text-secondary line-clamp-2 mb-50">
        {{ product.product_attributes.variant }}
      </p>
      <div class="item-wrapper">
        <div class="item-cost">
          <h5 class="item-price text-body d-flex justify-content-between">
            <span class="mr-1 bold">${{ product.range_price }}</span>
          </h5>
        </div>
      </div>

      <div
        v-if="
          product.product_attributes.is_edible &&
          product.product_attributes.nutritional_info
        "
        class="item-wrapper mt-auto"
      >
        <div class="item-rating">
          <nutri-score
            :nutritional-info="product.product_attributes.nutritional_info"
          />
        </div>
      </div>

      <b-button
        :variant="product.is_available ? 'success' : 'secondary'"
        class="btn__cart__small ml-auto mt-1"
        :disabled="!product.is_available"
        @click.stop="handleAddToCart"
      >
        <i class="fa fa-lg fa-cart-plus" />
      </b-button>
    </div>

    <div class="product__buttons">
      <b-button
        :variant="product.is_available ? 'success' : 'secondary'"
        class="btn__cart"
        @click.stop="handleAddToCart"
        size="sm"
        :disabled="!product.is_available"
      >
        <div v-if="product.is_available">
          <feather-icon icon="ShoppingCartIcon" class="mr-50" />
          <span class="btn__text"> Agregar</span>
        </div>

        <span class="btn__text" v-else>Agotado</span>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import vSelect from "vue-select"

import NutriScore from "../NutriScore.vue"

export default {
  components: {
    NutriScore,
    vSelect,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    addToCart: {
      type: Function,
      required: true,
    },
    handleStoreProductSelection: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleAddToCart() {
      this.addToCart({
        product: this.product.product_attributes,
        store_product: this.product,
      })
    },
  },
}
</script>
