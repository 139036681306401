<template>
  <header class="header__container">
    <div class="">
      <b-img
        :src="require('@/assets/images/pages/ecommerce-doodle.png')"
        class="bg-img"
      />
      <div class="position-relative">
        <div class="info container" v-if="establishment && landingPage">
          <div class="pl-1">
            <img
              v-if="establishment.logo"
              :src="establishment.logo"
              alt="Bramus"
              class="avatar"
            />
          </div>

          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column justify-content-center">
              <div v-if="establishment.name" class="establishment__name">
                {{ establishment.name }}
              </div>
              <div class="landing__title">
                {{ landingPage.title }}
              </div>
              <p class="landing__description font-italic">
                {{ landingPage.description }}
              </p>
            </div>

            <div class="d-flex align-items-center">
              <slot name="cp"></slot>
              <CartSidebar />

              <b-nav-item-dropdown right>
                <template #button-content>
                  <b-avatar
                    v-if="currentUser && currentUser.logo"
                    size="40"
                    :src="currentUser.logo"
                    variant="light-primary"
                    badge
                    class="badge-minimal"
                    badge-variant="success"
                  >
                  </b-avatar>
                  <feather-icon v-if="!currentUser" icon="UserIcon" size="22" />
                </template>
                <b-dropdown-item
                  v-if="!currentUser"
                  class="d-flex align-items-center"
                  v-b-modal.login-modal
                  link-class="w-100"
                  @click="() => $bvModal.show('login-modal')"
                >
                  <feather-icon size="16" icon="LogInIcon" class="mr-50" />
                  <span>Iniciar Sesión</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="currentUser"
                  class="d-flex align-items-center w-100"
                  link-class="w-100"
                  :to="{ name: 'home' }"
                >
                  <feather-icon size="16" icon="HomeIcon" class="mr-50" />
                  <span>Mi cuenta</span>
                </b-dropdown-item>
                <b-dropdown-item
                  class="d-flex align-items-center w-100"
                  link-class="w-100"
                  @click="() => $bvModal.show('contact-sales-modal')"
                >
                  <feather-icon size="16" icon="UserIcon" class="mr-50" />
                  <span>Contacto del negocio</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item
                  link-class="d-flex align-items-center"
                  @click="toggleDarkMode"
                >
                  <feather-icon
                    v-if="isDark"
                    size="16"
                    icon="SunIcon"
                    class="mr-50"
                  />
                  <feather-icon v-else size="16" icon="MoonIcon" class="mr-50" />
                  <span>
                    {{ isDark ? "Modo Claro" : "Modo Oscuro" }}
                  </span>
                </b-dropdown-item> -->
                <b-dropdown-item
                  v-if="currentUser"
                  class="d-flex align-items-center"
                  link-class="w-100"
                  @click="logout"
                >
                  <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                  <span>Cerrar Sesión</span>
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// Directives
import { mapGetters, mapMutations } from "vuex"

// Compo nents
import CartSidebar from "@/@core/components/checkout/CartSidebar.vue"

// Utils
import useJwt from "@/auth/jwt/useJwt"

export default {
  components: {
    CartSidebar,
  },
  data() {
    return {
      // isDark: localStorage.getItem("isDark") || false,
    }
  },
  computed: {
    ...mapGetters("landingPage", ["landingPage", "establishment"]),
    ...mapGetters("auth", ["currentUser"]),
  },
  methods: {
    ...mapMutations("auth", ["setCurrentUser"]),

    logout() {
      this.setCurrentUser(null)
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem("userData")
    },

    // toggleDarkMode() {
    //   localStorage.setItem("isDark", !this.isDark)
    //   this.isDark = !this.isDark
    //   document.body.classList.toggle("dark-layout")
    // },
  },
}
</script>

<style lang="scss">
.dropdown-toggle::after {
  display: none !important;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.header__container {
  position: sticky;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(10px);
  background-color: rgba(#fff, 0.7);
}

@supports (animation-timeline: scroll()) {
  .header__container {
    position: fixed;
    left: 0;
    right: 0;
    animation: add-shadow linear both;
  }

  .dark-layout {
    .header__container {
      animation: dark-add-shadow linear both;
    }
  }

  @keyframes adjust-info {
    to {
      grid-template-columns: 4em 1fr;
      gap: 1rem;
      padding: 0.5rem 0;
    }
  }
  @keyframes shrink-name {
    to {
      font-size: 1.5rem;
    }
  }
  @keyframes logo-margin {
    to {
      transform: translateY(0px);
      border: 0.25rem solid rgba(white, 0.2);
    }
  }
  @keyframes add-shadow {
    to {
      backdrop-filter: blur(10px);
      background-color: rgba(#fff, 0.7);
    }
  }

  @keyframes dark-add-shadow {
    to {
      backdrop-filter: blur(10px);
      background-color: rgba(#161d31, 0.7);
      box-shadow: 0 0px 15px 0 rgba(black, 0.4);
    }
  }
  @keyframes move-button {
    to {
      translate: 0% 40%;
    }
  }
  @keyframes move-and-fade-background {
    to {
      scale: 1.2;
      opacity: 0;
    }
  }
  @keyframes title-size {
    to {
      font-size: 1.2rem;
    }
  }
  @keyframes name-size {
    to {
      font-size: 0.8rem;
    }
  }
  @keyframes description-fx {
    to {
      opacity: 0;
      line-height: 0px;
    }
  }

  .bg-img {
    animation: move-and-fade-background linear both;
    animation-delay: 0.5s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .info {
    animation: adjust-info linear both;
  }
  .info h2 {
    animation: shrink-name linear both;
  }
  #button-edit {
    animation: move-button linear both;
  }
  .avatar {
    animation: logo-margin linear both;
  }
  .landing__title {
    animation: title-size linear both;
  }
  .establishment__name {
    animation: name-size linear both;
  }
  .landing__description {
    animation: description-fx linear both;
  }

  .info,
  h2,
  .header__container,
  #button-edit,
  .avatar,
  .landing__title,
  .establishment__name,
  .landing__description,
  .bg-img {
    animation-timeline: scroll();
    animation-range: 0 150px;
  }
}

.info {
  display: grid;
  align-items: end;
  grid-template-columns: 8em 1fr;
  gap: 2rem;
  padding: 1rem 0;
  @include media-breakpoint-down(md) {
    grid-template-columns: 5em 1fr;
  }
}

.bg-img {
  position: absolute;
  inset: 0;
  top: auto;
  z-index: -1;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.avatar {
  border-radius: 50%;
  border: 0.4rem solid rgba(white, 0.2);
  float: left;
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  transform: translateY(30px);

  @include media-breakpoint-down(md) {
    border: 0.3rem solid rgba(white, 0.2);
    transform: translateY(20px);
  }
}

.img-cover-fix {
  object-fit: cover;
  object-position: center;
}

.landing__title {
  font-size: 2rem;
  font-weight: 700;
  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
}

.dark-layout {
  .landing__title {
    color: #fff;
  }

  .establishment__name {
    color: #fff;
  }
}

.establishment__name {
  font-size: 1rem;
  font-weight: 700;
  @include media-breakpoint-down(md) {
    font-size: 0.7rem;
  }
}

.landing__description {
  line-height: 20px;
  @include media-breakpoint-down(md) {
    font-size: 0.7rem;
  }
}
</style>
