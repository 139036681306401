var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":("book-store-modal-" + (_vm.storeProduct.id) + "-" + _vm.uniqueId),"title":"Ingresa los datos a continuación","no-stacking":"","no-close-on-esc":"","no-close-on-backdrop":"","scrollable":"","body-class":"d-flex text-center pb-2 flex-column align-items-center","cancel-variant":"outline-secondary","cancel-title":"Cancelar","ok-variant":"primary","ok-title":"Continuar","ok-disabled":!_vm.name || !_vm.email || !_vm.contact,"hide-footer":""},on:{"hide":_vm.reset}},[_c('div',{staticClass:"p-2"},[_c('small',{staticClass:"px-2 py-1 mx-auto"},[_vm._v(" Cuando añades un producto o servicio de este tipo al carrito, es imprescindible proporcionar el nombre de la persona a la que va dirigido ")]),(_vm.currentStore && _vm.currentStore.store_type === 'book_store')?_c('b-badge',{attrs:{"pill":"","variant":'light-warning'}},[_c('p',[_vm._v("Recuerda: los libros se recogeran en la escuela")]),_c('p',[_vm._v("Escribe el nombre completo del alumno")])]):_vm._e(),_c('validation-observer',{ref:"addPayeeRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit()}}},[(_vm.storeReferences.includes('name'))?_c('div',{staticClass:"my-1"},[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : valid ? true : null,"placeholder":"Nombre","name":"name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(_vm.storeReferences.includes('email'))?_c('div',{staticClass:"my-1"},[_c('b-form-group',{attrs:{"label":"E-mail","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : valid ? true : null,"name":"email","placeholder":"E-mail"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(_vm.storeReferences.includes('contact'))?_c('div',{staticClass:"my-1"},[_c('b-form-group',{attrs:{"label":"Telefono","label-for":"contact"}},[_c('validation-provider',{attrs:{"name":"contact","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : valid ? true : null,"placeholder":"Telefono","name":"contact","type":"number"},model:{value:(_vm.contact),callback:function ($$v) {_vm.contact=$$v},expression:"contact"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){return _vm.$bvModal.hide(
                  ("book-store-modal-" + (_vm.storeProduct.id) + "-" + _vm.uniqueId)
                )}}},[_vm._v(" Cancelar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" Continuar ")])],1)])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }