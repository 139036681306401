<template>
  <b-card
    v-if="product && selectedStoreProduct"
    @click="handleStoreProductSelection(product, selectedStoreProduct)"
    class="product__card mb-0"
    no-body
    text-variant="primary"
  >
    <div class="product__image__container">
      <b-img
        :alt="`${product.name}-${product.id}`"
        fluid
        rounded
        :src="`${product.logo}`"
        class="product__image"
      />
    </div>

    <div class="product__details">
      <b-card-text class="bold mb-0 pb-0 h5">
        {{ product.name }}
      </b-card-text>

      <p v-if="product.variant" class="text-secondary line-clamp-2">
        {{ product.variant }}
      </p>

      <div v-if="selectedStoreProduct" class="item-wrapper mt-50">
        <div class="item-cost">
          <h5 class="item-price text-body d-flex justify-content-between">
            <span class="mr-1 bold"
              >${{ selectedStoreProduct.unit_price | money }}</span
            >
          </h5>
        </div>
      </div>

      <div v-if="bestPromotion" class="pt-1">
        <b-badge
          v-if="bestPromotion.promo_type !== 'free_product'"
          pill
          :variant="'light-info'"
          class="text-break text-wrap"
        >
          <span>
            {{ "Compra " + bestPromotion.needs_to_buy + " y gana" }}
          </span>
          <span v-if="bestPromotion.promo_type === 'price_discount'">
            {{ bestPromotion.discount + "% de descuento" }}
          </span>
          <span v-else-if="bestPromotion.promo_type === 'reward_points'">
            {{ bestPromotion.reward_points + "% en puntos" }}
          </span>
        </b-badge>

        <b-badge
          v-if="bestPromotion.promo_type === 'free_product'"
          pill
          :variant="'light-info'"
        >
          {{ bestPromotion.needs_to_buy }} {{ "x" }}
          {{ bestPromotion.gets_you }}
        </b-badge>
      </div>

      <div
        v-if="product.is_edible && product.nutritional_info"
        class="item-wrapper py-50"
      >
        <div class="item-rating">
          <nutri-score :nutritional-info="product.nutritional_info" />
        </div>
      </div>

      <div
        v-if="product.store_products && product.store_products.length > 1"
        class="mt-1"
      >
        <span> * Elige una opción: </span>
        <b-form-select
          v-model="selectedVariant"
          @change="handleStoreProductChange"
          @click.native.stop
        >
          <b-form-select-option
            v-for="(option, index) in storeProductOptions"
            :key="index"
            :value="option.value"
          >
            {{ option.text }}
          </b-form-select-option>
        </b-form-select>
      </div>

      <div class="d-flex justify-end mt-1 w-100">
        <b-button
          :variant="
            selectedStoreProduct.inventory === '0.0' ? 'secondary' : 'success'
          "
          class="btn__cart__small ml-auto"
          @click.stop="handleAddToCart(false)"
          :disabled="selectedStoreProduct.inventory === '0.0'"
        >
          <i class="fa fa-lg fa-cart-plus" />
        </b-button>
      </div>
    </div>

    <div class="product__buttons">
      <b-button
        :variant="
          selectedStoreProduct.inventory === '0.0' ? 'secondary' : 'success'
        "
        class="btn__cart"
        @click.stop="handleAddToCart(false)"
        size="sm"
        v-ripple
        :disabled="selectedStoreProduct.inventory === '0.0'"
      >
        <div v-if="selectedStoreProduct.inventory > '0.0'">
          <feather-icon icon="ShoppingCartIcon" class="mr-50" />
          <span class="btn__text">Agregar</span>
        </div>
        <span v-else class="btn__text">Agotado</span>
      </b-button>
    </div>

    <store-product-add-modal
      :product="product"
      :storeProduct="selectedStoreProduct"
      :uniqueId="uniqueId"
      @on-ok="updateStoreProduct"
    />

    <store-product-reference-modal
      :storeProduct="selectedStoreProduct"
      :uniqueId="uniqueId"
      @on-submit="(event) => updateStoreProduct(event, true)"
    />
  </b-card>
</template>

<script>
import vSelect from "vue-select"

// Components
import NutriScore from "../NutriScore.vue"
import StoreProductAddModal from "./store-product-add-modal.vue"
import StoreProductReferenceModal from "./StoreProductReferenceModal.vue"

// Utils
import { getBestPromotion } from "@/@core/utils/store-product-utils"
import { mapGetters } from "vuex"

export default {
  components: {
    vSelect,
    NutriScore,
    StoreProductAddModal,
    StoreProductReferenceModal,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    addToCart: {
      type: Function,
      required: true,
    },
    handleStoreProductSelection: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selectedVariant: this.product.store_products[0].id,
      selectedStoreProduct: null,
      uniqueId: Math.random().toString(36).substring(7),
    }
  },
  mounted() {
    this.initializeSelectedStoreProduct()
  },
  methods: {
    getBestPromotion,

    handleMinus() {
      if (this.storeProductUnits === 1) return

      this.selectedStoreProduct.units = Number(this.storeProductUnits) - 1
    },

    handlePlus() {
      if (this.storeProductUnits < this.selectedStoreProduct.inventory) {
        this.selectedStoreProduct.units = Number(this.storeProductUnits) + 1
      }
    },

    handleStoreProductChange() {
      this.selectedStoreProduct = {
        ...this.product.store_products.find(
          (storeProduct) => storeProduct.id === this.selectedVariant
        ),
        units: 1,
      }
    },

    updateStoreProduct(storeProduct, skip = false) {
      this.selectedStoreProduct = storeProduct
      this.handleAddToCart(true, skip)
    },

    handleAddToCart(skipValidation = false, skip) {
      if (!skipValidation) {
        this.$bvModal.show(
          `store-product-add-modal-${this.selectedStoreProduct.id}-${this.uniqueId}`
        )
        return
      }

      this.addToCart({
        product: this.product,
        storeProduct: this.selectedStoreProduct,
        bestPromotion: this.bestPromotion,
        units: this.storeProductUnits,
        uniqueId: this.uniqueId,
        skipReferences: skip,
      })
    },

    initializeSelectedStoreProduct() {
      this.selectedStoreProduct = {
        ...this.product.store_products[0],
        units: 1,
      }
    },
  },
  computed: {
    ...mapGetters("stores", ["storeReferences"]),
    bestPromotion() {
      if (
        !this.selectedStoreProduct ||
        !this.selectedStoreProduct.promotion_attributes
      )
        return

      return this.getBestPromotion(this.selectedStoreProduct)
    },

    storeProductUnits() {
      return Number(this.selectedStoreProduct.units)
    },

    storeProductOptions() {
      if (
        !this.product.store_products ||
        this.product.store_products.length < 2
      ) {
        return
      }

      return (
        this.product?.store_products?.map((store_product) => {
          const variantNames =
            store_product.store_product_variant_attributes
              ?.map((variant) => variant.variant_option?.option_name || "")
              .join(" ") || `$${store_product.unit_price}`
          return {
            value: store_product.id,
            text: variantNames,
          }
        }) || []
      )
    },
  },
}
</script>
