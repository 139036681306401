<template>
  <div class="cart cart-responsive p-1" tabindex="-1">
    <transition-group name="list" tag="div">
      <div
        v-for="product in cartByReference"
        :key="product.id + JSON.stringify(product.references)"
        v-b-modal.product-modal
        class="mb-50 p-1 product list-item shadow overflow-auto"
        no-body
        @click="handleEdit(product)"
      >
        <div class="d-flex justify-content-center">
          <div>
            <b-img
              :src="product.product_attributes.logo"
              width="80"
              height="80"
              rounded
            />
          </div>
        </div>
        <div class="px-1 d-flex flex-column">
          <div class="product-name mb-0 font-weight-bold">
            {{ product.product_attributes.name }}
          </div>
          <div class="font-weight-bolder">
            {{ product.product_attributes.variant }}
          </div>
          <div
            class="font-weight-bolder"
            v-if="
              product.store_product_variant_attributes.length &&
              product.store_product_variant_attributes[0]
                .variant_option_attributes
            "
          >
            {{
              product.store_product_variant_attributes[0]
                .variant_option_attributes.option_name
            }}
          </div>
          <div
            v-if="storeHasReferences && product.references"
            class="para my-50"
          >
            <div v-if="product.references['name']">
              {{ product.references["name"] }}
            </div>
            <div class="email font-italic" v-if="product.references['email']">
              <span class="material-symbols-outlined"> mail </span>
              {{ product.references["email"] }}
            </div>
            <div class="phone font-italic" v-if="product.references['contact']">
              <span class="material-symbols-outlined"> phone </span>
              +52 {{ product.references["contact"] }}
            </div>
          </div>
          <div
            class="text-info product-quantity"
            v-if="product.product_attributes"
          >
            x
            <span v-if="product.product_attributes.is_weighted">
              {{ product.units }} kg
            </span>
            <span v-else>{{ product.units | money }}</span>
          </div>
        </div>
        <div>
          <div
            class="h3 d-flex align-items-center pr-1 text-primary product-price"
          >
            ${{ product.itemTotal | money }}
          </div>
          <div
            class="
              d-flex
              align-items-center
              justify-content-end
              pr-1
              text-danger
              product-price
            "
          >
            <b-button
              variant="outline-danger"
              size="sm"
              pill
              @click.stop="deleteProductFromCart(product)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </div>
        <bascula-modal
          :product="product"
          :uniqueId="product.uniqueId"
          @edit-units="editUnits(product, $event)"
        />
      </div>
    </transition-group>
    <div v-if="cart.length > 0" class="product-count text-center text-muted">
      <div class="mt-2 mb-1 h4">
        {{ cartProductsCount }} Producto<span v-if="cartProductsCount > 1"
          >s</span
        >
      </div>
      <b-button
        tabindex="-1"
        class="text-muted"
        variant="outline-secondary"
        size="sm"
        @click="warningBeforeEmptyCart()"
      >
        <i class="fas fa-trash mr-1" /> Borrar
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"

import ProductModal from "@/views/pos/ProductModal.vue"
import BookProductModal from "@core/components/WallEateCommerce/BookProductModal.vue"
import BasculaModal from "@core/components/bascula-modal.vue"

export default {
  components: {
    ProductModal,
    BasculaModal,
    BookProductModal,
  },
  data() {
    return {
      selectedProduct: null,
      uniqueBasculaId: Math.random().toString(36).substring(7),
    }
  },
  props: {
    products: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  computed: {
    ...mapGetters("cart", [
      "cart",
      "cartDetails",
      "cartByReference",
      "cartProductsCount",
    ]),
    ...mapGetters("stores", ["currentStore", "storeHasReferences"]),
  },
  methods: {
    ...mapActions("cart", ["emptyCart"]),

    ...mapMutations("cart", ["editCartItemQuantity"]),

    editUnits(product, units) {
      this.editCartItemQuantity({ cartItem: product, units })
    },

    deleteProductFromCart(product) {
      this.$swal({
        title: "Confirma...",
        icon: "warning",
        text: "¿Estás seguro que deseas eliminar el producto del carrito?",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "Si, eliminar",
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.editUnits(product, 0)
        }
      })
    },
    warningBeforeEmptyCart() {
      this.$swal({
        title: "Confirma...",
        icon: "warning",
        text: "¿Estás seguro que deseas eliminar los productos del carrito?...esta acción no se puede revertir",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "Si, eliminar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger mr-1",
        },
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.emptyCart()
        }
      })
    },

    handleEdit(product) {
      this.$bvModal.show(`bascula-modal-${product.id}-${product.uniqueId}`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/colors/palette-variables.scss";
.dark-layout {
  .cart {
    background-color: #12182c !important;

    .product {
      background: #161d31 !important;

      &:hover {
        background: #1b243d !important;
      }

      .product-name {
        color: #fff !important;
      }
    }
  }
}

.cart {
  background-color: $body-bg;
  overflow-y: auto;
  height: 100%;
  .product {
    border-radius: 5px;
    overflow: hidden;
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    transition: transform 0.25s ease;
    &:hover {
      background: #f8f9fa;
    }
    .product-name {
      font-size: 18px;
      color: $black;
    }
  }
  .product-count {
    margin-bottom: 30px !important;
  }

  @media (max-width: 770px) {
    .product {
      .product-name {
        font-size: 1rem;
      }

      .product-price {
        font-size: 1.2rem;
      }

      .product-quantity {
        font-size: 1rem;
      }
    }
  }
}
.list-item {
  display: block;
}
.list-enter-active,
.list-leave-active {
  transition: all 5s ease;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translatex(800px);
}

.logo {
  filter: invert(0.1);
  transition: filter 0.3s ease;
}

@media (prefers-color-scheme: dark) {
  .logo {
    filter: none;
  }
}

.para {
  border: rgba(#f8f9fa, 0.5) 1px dashed;
  border-radius: 5px;
  padding: 5px;

  .email {
    display: flex;
    align-items: center;
    font-size: 10px;
    span {
      margin-right: 5px;
      font-size: 10px;
    }
  }

  .phone {
    display: flex;
    align-items: center;
    font-size: 10px;
    span {
      margin-right: 5px;
      font-size: 10px;
    }
  }
}
</style>
