<template>
  <div>
    <b-modal
      :id="`book-store-modal-${storeProduct.id}-${uniqueId}`"
      title="Ingresa los datos a continuación"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      scrollable
      body-class="d-flex text-center pb-2 flex-column align-items-center"
      cancel-variant="outline-secondary"
      cancel-title="Cancelar"
      ok-variant="primary"
      ok-title="Continuar"
      :ok-disabled="!name || !email || !contact"
      @hide="reset"
      hide-footer
    >
      <div class="p-2">
        <small class="px-2 py-1 mx-auto">
          Cuando añades un producto o servicio de este tipo al carrito, es
          imprescindible proporcionar el nombre de la persona a la que va
          dirigido
        </small>
        <b-badge
          v-if="currentStore && currentStore.store_type === 'book_store'"
          pill
          :variant="'light-warning'"
        >
          <p>Recuerda: los libros se recogeran en la escuela</p>
          <p>Escribe el nombre completo del alumno</p>
        </b-badge>

        <validation-observer ref="addPayeeRules" v-slot="{ invalid }">
          <b-form class="mt-2" @submit.prevent="handleSubmit()">
            <div v-if="storeReferences.includes('name')" class="my-1">
              <b-form-group label="Nombre" label-for="name">
                <validation-provider
                  #default="{ errors, valid }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    v-model="name"
                    :state="errors.length > 0 ? false : valid ? true : null"
                    placeholder="Nombre"
                    name="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div v-if="storeReferences.includes('email')" class="my-1">
              <b-form-group label="E-mail" label-for="email">
                <validation-provider
                  #default="{ errors, valid }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="email"
                    :state="errors.length > 0 ? false : valid ? true : null"
                    name="email"
                    placeholder="E-mail"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div v-if="storeReferences.includes('contact')" class="my-1">
              <b-form-group label="Telefono" label-for="contact">
                <validation-provider
                  #default="{ errors, valid }"
                  name="contact"
                  rules="required"
                >
                  <b-form-input
                    v-model="contact"
                    :state="errors.length > 0 ? false : valid ? true : null"
                    placeholder="Telefono"
                    name="contact"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="mr-1"
                variant="outline-secondary"
                @click="
                  $bvModal.hide(
                    `book-store-modal-${storeProduct.id}-${uniqueId}`
                  )
                "
              >
                Cancelar
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
                :disabled="invalid"
              >
                Continuar
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
// Directives
import { mapGetters } from "vuex"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { removeEmptyValues } from "@/utils/utils"

export default {
  props: {
    storeProduct: {
      type: Object,
      required: true,
    },
    uniqueId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      email: "",
      contact: "",
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    ...mapGetters("stores", ["currentStore", "storeReferences"]),
  },
  methods: {
    handleSubmit() {
      const references = {
        name: this.name,
        email: this.email,
        contact: this.contact,
      }

      this.$emit("on-submit", {
        ...this.storeProduct,
        references: removeEmptyValues(references),
      })

      this.$bvModal.hide(
        `book-store-modal-${this.storeProduct.id}-${this.uniqueId}`
      )
    },
    reset() {
      this.name = ""
      this.email = ""
      this.contact = ""
    },
  },
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.delete-button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.students-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

.list-group {
  position: relative;
  cursor: pointer;
}

.book-reference {
  &:hover {
    background-color: #161d31;
  }
}
</style>
