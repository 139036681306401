<template>
  <b-modal
    id="ask-postal-code-modal"
    hide-footer
    centered
    title="Ingresa tu código postal"
    no-stacking
    @hidden="handleHideModal"
  >
    <div class="px-2 pb-2">
      <validation-observer>
        <form @submit.prevent="handleSubmit">
          <validation-provider
            rules="required|zip_code"
            v-slot="{ errors, valid }"
            name="Código postal"
          >
            <b-input-group class="mt-3">
              <template #append>
                <b-button
                  :variant="
                    errors.length > 0 ? 'danger' : valid ? 'success' : 'primary'
                  "
                  :disabled="errors.length > 0 || !valid"
                  type="submit"
                >
                  Buscar
                </b-button>
              </template>
              <b-form-input
                v-model="postalCode"
                type="text"
                placeholder="Ingresa tu código postal"
                :state="errors.length > 0 ? false : valid ? true : null"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </form>
      </validation-observer>

      <b-button
        v-if="isBackButton"
        class="p-0 mt-2"
        variant="link"
        @click="goBack"
      >
        Atrás
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate"

import { FormWizard, TabContent } from "vue-form-wizard"

export default {
  components: {
    ValidationObserver,
    ValidationProvider,

    FormWizard,
    TabContent,
  },
  props: {
    isBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    goBack: {
      type: Function,
      required: false,
    },
    onSubmit: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      postalCode: null,
    }
  },
  methods: {
    handleHideModal() {
      this.postalCode = null
    },
    handleSubmit() {
      this.$bvModal.hide("ask-postal-code-modal")

      this.onSubmit(this.postalCode)
      this.$emit("onSubmit", this.postalCode)
    },
  },
}
</script>

<styles lang="scss">
.custom-control-primary {
  .custom-control-label {
    font-size: 1rem;
  }
}

.vue-form-wizard {
  .wizard-header {
    display: none;
  }
  ul.wizard-nav-pills,
  .wizard-progress-with-circle {
    display: none !important;
  }
}
</styles>

<style lang="scss" scoped></style>
