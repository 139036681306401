// Directives
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters("pos", ["cart"]),
    ...mapGetters("stores", ["currentStore"]),
    ...mapGetters("stripe", ["storeAccount"]),

    showCodiButton() {
      if (!this.currentStore) return false

      return this.currentStore.accepted_payment_types.includes("codi") 
    },
    showCashButton() {
      if (!this.currentStore) return false
      
      return this.currentStore.accepted_payment_types.includes("cash")
    },
    showBankCardButton() {
      if (!this.currentStore) return false

      return this.currentStore.accepted_payment_types.includes("bankcard") && this.storeAccount
    },
    showRewardPointsButton() {
      if (!this.currentStore) return false
      
      return this.currentStore.accepted_payment_types.includes("reward_points")
    },
    showBankDepositButton() {
      if (!this.currentStore) return false
      
      return this.currentStore.accepted_payment_types.includes("bank_deposit") && this.storeAccount
    },
  },
}
