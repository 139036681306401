<template>
  <b-modal
    id="contact-sales-modal"
    hide-footer
    size="md"
    title="Contacto del Marketplace"
  >
    <div v-if="establishment" class="p-2">
      <b-img
        v-if="establishment.logo"
        :src="establishment.logo"
        fluid
        alt="Bramus"
        class="mb-2"
        style="width: 100px; height: 100px"
      />

      <h4 class="mb-2">
        {{ establishment.name }}
      </h4>
      <div class="mb-2">
        <h5>Nombre de contacto</h5>
        <span>
          {{ establishment.contact_name }}
        </span>
      </div>
      <div class="mb-2">
        <h5>Correo de contacto</h5>
        <span>
          {{ establishment.contact_email }}
        </span>
      </div>
      <GoogleMap
        v-if="
          establishment.address_attributes &&
          establishment.address_attributes.lat &&
          establishment.address_attributes.lng
        "
        :lat="parseFloat(establishment.address_attributes.lat)"
        :lng="parseFloat(establishment.address_attributes.lng)"
      />
    </div>
  </b-modal>
</template>

<script>
import GoogleMap from "@/@core/components/GoogleMap.vue"

export default {
  components: {
    GoogleMap,
  },
  props: {
    establishment: {
      type: Object,
      default: null,
    },
  },
}
</script>
