export const filterUniqueById = (items) => {
  const seenIds = new Set()
  const uniqueItems = []

  for (const item of items) {
    if (seenIds.has(item.id)) continue

    seenIds.add(item.id)
    uniqueItems.push(item)
  }

  return uniqueItems
}

export function removeEmptyValues(obj) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v !== null && v !== "")
      .map(([k, v]) => {
        if (Array.isArray(v)) {
          return [k, v.map(item => (item && typeof item === "object" ? removeEmptyValues(item) : item)).filter(item => item !== null && item !== "")]
        } else if (v && typeof v === "object") {
          return [k, removeEmptyValues(v)]
        }
        return [k, v]
      })
  )
}
