<template>
  <div v-if="!loading">
    <LandingPageHeader>
      <template v-slot:cp>
        <div class="d-flex align-items-center">
          <feather-icon
            v-if="postalCode || currentDeliveryOption === 'not_applicable'"
            class="cursor-pointer mr-50 d-none d-md-flex"
            icon="XIcon"
            size="24"
            @click="resetAll"
          />

          <b-button
            class="d-none d-md-flex ml-auto"
            size="sm"
            variant="outline-primary"
            @click="handleChangePostalCode"
          >
            <feather-icon
              icon="MapPinIcon"
              v-b-toggle.store-product-filters
              class="mr-50"
            />
            {{ postalCode ? postalCode : "Seleccionar" }}
          </b-button>
        </div>
      </template>
    </LandingPageHeader>

    <div class="landing__banner container">
      <LandingPageBanner />
    </div>

    <StoreProductFilters
      :filters="filters"
      :categories="categories"
      :products="storeProducts"
      :onChange="handleOnChangeFilters"
    />

    <main class="main__contaner container">
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          searchbar-container
          my-3
          flex-wrap
        "
      >
        <div class="d-flex align-items-center">
          <feather-icon
            icon="FilterIcon"
            size="21"
            v-b-toggle.store-product-filters
          />

          <Searchbar ref="searchbar" class="ml-1" @onChange="setQuery" />
        </div>
        <div class="d-flex justify-content-between ml-auto align-items-center">
          <feather-icon
            v-if="postalCode || currentDeliveryOption === 'not_applicable'"
            class="cursor-pointer mr-50 d-md-none"
            icon="XIcon"
            size="24"
            @click="resetAll"
          />

          <b-button
            class="d-md-none mr-2 mt-2"
            size="sm"
            variant="outline-primary"
            @click="handleChangePostalCode"
          >
            <feather-icon
              icon="MapPinIcon"
              v-b-toggle.store-product-filters
              class="mr-50"
            />
            {{ postalCode ? postalCode : "Seleccionar" }}
          </b-button>
          <div
            class="d-flex align-items-center mt-2"
            v-if="storeOptions.length"
          >
            <store-icon class="mr-1" />
            <b-form-select
              ref="storeSelect"
              :value="selectedStore"
              :options="storeOptions"
              class="w-fit"
              @change="handleStoreChange"
            ></b-form-select>
          </div>
        </div>
      </div>

      <StoreProductList
        :products="storeProducts"
        :withVariants="has_store"
        :handleAddToCart="handleAddToCart"
        :handleStoreProductSelection="handleStoreProductSelection"
      />

      <div
        class="d-flex justify-content-center py-4"
        v-if="storeProducts.length > 0"
      >
        <PaginationSimple
          :pagination="pagination"
          :handlePagination="handlePagination"
          :loading="loading"
        />
      </div>
    </main>

    <!-- <ScrollToTop /> -->

    <PostalCodeModal
      :goBack="goBack"
      :isBackButton="true"
      :onSubmit="handlePostalCodeSubmit"
    />

    <DeliveryOptionsModal @update:deliveryOption="deliveryOption = $event" />

    <StoreProductOptionsModal
      :selectedProduct="selectedProduct"
      :clearFilters="clearFilters"
      :toggleFiltering="toggleFiltering"
    />
    <StoreProductDetailsModal :storeProduct="selectedStoreProduct" />

    <LoginModal />
    <LandingPageContactModal :establishment="establishment" />
  </div>
</template>

<script>
// Directives
import { mapActions, mapMutations, mapGetters } from "vuex"

// Components
import StoreIcon from "@/components/icons/store-icon.vue"
import ProductPromotionsModal from "@/@core/components/WallEateCommerce/ProductPromotionsModal.vue"

import StoreProductList from "@/@core/components/store-products/StoreProductList.vue"
import StoreProductFilters from "@/@core/components/store-products/StoreProductFilters.vue"
import StoreProductOptionsModal from "@/@core/components/store-products/StoreProductOptionsModal.vue"
import StoreProductDetailsModal from "@/@core/components/store-products/StoreProductDetailsModal.vue"

import LandingPageHero from "@/@core/components/landing-page/LandingPageHero.vue"
import LandingPageBanner from "@/@core/components/landing-page/LandingPageBanner.vue"
import LandingPageHeader from "@/@core/components/landing-page/LandingPageHeader.vue"
import LandingPageContactModal from "@/@core/components/landing-page/LandingPageContactModal.vue"

import Searchbar from "@/@core/components/Searchbar.vue"
import LoginModal from "@/@core/components/LoginModal.vue"
import ScrollToTop from "@/@core/components/scroll-to-top/ScrollToTop.vue"
import PostalCodeModal from "@/@core/components/PostalCodeModal.vue"
import PaginationSimple from "@/@core/components/PaginationSimple.vue"
import DeliveryOptionsModal from "@core/components/DeliveryOptionsModal.vue"

// Mixins
import {
  useShopRemoteData,
  useShopFiltersSortingAndPagination as useFilters,
} from "@/views/stores/useStoreProducts"
import cartMixin from "@/@core/mixins/cartMixin"
import messagesMixin from "@/@core/mixins/messagesMixin"

import { filterUniqueById } from "@/utils/utils"

export default {
  components: {
    StoreIcon,
    StoreProductList,
    StoreProductFilters,
    StoreProductOptionsModal,
    StoreProductDetailsModal,

    LandingPageHero,
    LandingPageBanner,
    LandingPageHeader,
    LandingPageContactModal,

    ProductPromotionsModal,

    LoginModal,
    Searchbar,
    ScrollToTop,
    PostalCodeModal,
    PaginationSimple,
    DeliveryOptionsModal,
  },
  data() {
    return {
      token: this.$route.query.token,
      store_id: this.$route.query.store_id,
      perPage: 20,
      loading: false,
      has_store: false,
      selectedStore: null,
      previousStore: null,
      selectedStoreProduct: null,
      stopFiltering: false,
      selectedDeliveryOption: null,
      categories: [],
      selectedProduct: null,
      deliveryOption: null,
      deliveryOptions: [
        { value: "pickup", text: "Recoger en tienda" },
        { value: "delivery", text: "Entrega a domicilio" },
        { value: "both", text: "Ambos" },
      ],
      appLoading: document.getElementById("loading-bg"),
      postalCode: localStorage.getItem("postal_code") || null,
      showInstallPrompt: localStorage.getItem("install_prompt") !== "accepted",
      deferredPrompt: null,
      installToastId: "install-toast",
    }
  },
  mixins: [messagesMixin, cartMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.resetStates()
      vm.fetchInitialData()
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.resetStates()
    this.fetchInitialData()
    next()
  },
  setup() {
    const { filters } = useFilters()

    const { categories, fetchCategories } = useShopRemoteData()

    return {
      // Data
      filters,
      categories,

      // Methods
      fetchCategories,
    }
  },
  watch: {
    selectedStore(val) {
      if (val) {
        if (this.store_id) return

        const store = this.nearbyStores.find((store) => store.id === val)

        if (this.store && store.id === this.store.id) return

        this.setStore(store)
      }
    },
    deliveryOption(val) {
      if (!val) return

      this.selectedDeliveryOption = val

      if (val === "not_applicable") {
        this.postalCode = null
        this.handleDeliveryOptionNotApplicable()
      } else {
        this.$bvModal.show("ask-postal-code-modal")
      }

      this.deliveryOption = null
    },
    store: {
      handler(val) {
        if (!val) return

        if (this.currentStore) {
          this.emptyCart()
        }

        this.setCurrentStore(val)
        this.selectedStore = val.id
        this.previousStore = this.store.id

        this.$swal({
          title: "Cargando productos",
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          },
        })

        this.fetchGroupedStoreProducts({
          by_store: this.store.id,
          by_active_status: true,
          by_token: this.token,
          meta: {
            pagination: {
              per_page: this.perPage,
            },
          },
        })
          .then((response) => {
            this.has_store = true
            this.setStoreProducts(response)
          })
          .finally(() => {
            this.$swal.close()
          })

        this.fetchCategories({
          by_active_status: true,
          by_store: this.store.id,
          meta: { pagination: { per_page: 10000 } },
        }).then((response) => {
          this.categories = response.data
        })

        this.fetchStoreAccount({ id: this.store.id })
      },
      immediate: true,
    },
  },
  created() {
    const lastReload = localStorage.getItem("lastReload")

    if (!lastReload || Date.now() - lastReload > 5000) {
      localStorage.setItem("lastReload", Date.now())
      window.location.reload()
    }

    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault()
      this.deferredPrompt = event

      this.showInstallToast()
    })
  },
  destroyed() {
    this.resetStates()
  },
  methods: {
    ...mapActions("stores", ["fetchShortStores", "fetchShortStore"]),
    ...mapActions("landingPage", ["fetchLandingPage"]),
    ...mapActions("storeProducts", [
      "fetchPublicStoreProducts",
      "fetchGroupedStoreProducts",
    ]),
    ...mapActions("stripe", ["fetchStoreAccount"]),
    ...mapActions("cart", ["emptyCart", "addCartItem"]),

    ...mapMutations("storeProducts", [
      "setStoreProducts",
      "setAvailableStores",
    ]),
    ...mapMutations("landingPage", ["setStore", "setCurrentDeliveryOption"]),
    ...mapMutations("stores", ["setCurrentStore", "setNearbyStores"]),

    installApp() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt()
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("El usuario aceptó el prompt de instalación")
          } else {
            console.log("El usuario rechazó el prompt de instalación")
          }
          this.deferredPrompt = null
          localStorage.setItem("install_prompt", "accepted")
          this.showInstallButton = false
        })
      }

      this.$bvToast.hide(this.installToastId)
    },

    clearFilters() {
      this.$refs.searchbar.searchQuery = ""
      this.filters = {
        categories: null,
        nutriScore: null,
        q: null,
      }
    },

    toggleFiltering() {
      this.stopFiltering = !this.stopFiltering
    },

    handleOnChangeFilters() {
      if (this.stopFiltering) return

      if (
        (this.postalCode && this.store) ||
        (this.currentDeliveryOption === "not_applicable" && this.store) ||
        (this.store && (this.store_id || this.token))
      ) {
        this.fetchGroupedStoreProducts({
          by_store: this.store ? this.store.id : null,
          by_active_status: true,
          by_category: this.filters.categories || null,
          by_nutritional_info: this.filters.nutriScore || null,
          by_name: this.filters.q || null,
          by_token: this.token || null,
          meta: {
            pagination: {
              per_page: this.perPage,
            },
          },
        })
          .then((response) => {
            this.has_store = true
            this.setStoreProducts(response)
          })
          .finally(() => {
            this.$swal.close()
          })
      } else {
        this.fetchPublicStoreProducts({
          by_establishment: this.landingPage.establishment_id,
          by_distinct_product: true,
          by_category: this.filters.categories || null,
          by_nutritional_info: this.filters.nutriScore || null,
          by_name: this.filters.q || null,
          by_store: this.nearbyStoreIds,
          meta: {
            pagination: {
              page: this.page,
              per_page: this.perPage,
            },
          },
        })
          .then((response) => {
            this.setStoreProducts(response)
          })
          .finally(() => {
            this.$swal.close()
          })
      }
    },

    async handleDeliveryOptionNotApplicable() {
      try {
        await this.handleGeneralPostalCodeSubmit()
      } finally {
        this.$bvModal.hide("delivery-options-modal")
        this.$bvModal.hide("ask-postal-code-modal")
      }
    },

    showInstallToast() {
      const h = this.$createElement

      const $rejectButton = h(
        "b-button",
        {
          props: {
            variant: "ghost",
            size: "sm",
          },
          on: {
            click: () => {
              this.$bvToast.hide(this.installToastId)
            },
          },
        },
        "Cancelar"
      )

      const $installButton = h(
        "b-button",
        {
          props: {
            variant: "primary",
            size: "sm",
          },
          class: "ml-1",
          on: {
            click: () => {
              this.installApp()
            },
          },
        },
        "Instalar"
      )

      this.$bvToast.toast([$rejectButton, $installButton], {
        id: this.installToastId,
        title: "Deseas instalar la aplicación a tu pantalla de inicio?",
        variant: "info",
        solid: true,
        toaster: "b-toaster-bottom-right",
        noAutoHide: true,
      })
    },

    setLayoutTheme() {
      if (this.establishment.dark_theme_enabled) {
        document.body.classList.add("dark-layout")
      } else {
        document.body.classList.remove("dark-layout")
        document.body.style.backgroundColor = "#fff"
      }
    },

    isPostalCodeValid() {
      return this.postalCode && this.postalCode !== "null"
    },

    async fetchInitialData() {
      this.showLoading()
      try {
        await this.fetchLandingPage(this.$route.params.slug)

        this.setLayoutTheme()

        if (this.store_id || this.token) {
          await this.initialFetchWithStoreId(this.store_id, this.token)
          return
        }

        if (this.isPostalCodeValid()) {
          await this.initialFetchWithPostalCode()
        } else {
          await this.initialFetchWithoutPostalCode()
        }
      } catch (e) {
        console.error(e)
        this.handleFetchError(e.response.data, "Error al cargar la página")
        this.resetAll()
        this.$router.push({ name: "home" })
      } finally {
        this.hideLoading()
      }
    },

    async initialFetchWithStoreId(storeId, token) {
      const res = await this.fetchShortStore(storeId)
      this.setStore(res)
      this.setCurrentStore(res)
      this.setCurrentDeliveryOption(res.delivery_option)
    },

    async initialFetchWithPostalCode() {
      this.setCurrentDeliveryOption(localStorage.getItem("delivery_option"))
      const nearbyStoresResponse = await this.getNearbyStores({
        by_delivery_option: this.currentDeliveryOption,
        by_postal_code: this.postalCode,
        with_store_products: true,
      })

      const storeIds = nearbyStoresResponse.data.map((store) => store.id)

      if (storeIds.length === 1) {
        this.setNearbyStores(nearbyStoresResponse)
        this.setStore(nearbyStoresResponse.data[0])
        return
      }

      const publicStoreProducts = await this.getPublicStoreProducts({
        by_distinct_product: true,
        by_store: storeIds,
      })
      this.setStoreProducts(publicStoreProducts)

      await this.getCategories({
        by_store: storeIds,
      })
      this.setNearbyStores(nearbyStoresResponse)
    },
    async initialFetchWithoutPostalCode() {
      this.postalCode = null
      const res = await this.getPublicStoreProducts({
        by_establishment: this.landingPage.establishment_id,
        by_distinct_product: true,
        by_store_category: "online",
      })
      this.setStoreProducts(res)
      await this.getCategories({
        by_active_status: true,
        by_establishment: this.landingPage.establishment_id,
      })
    },

    async getNearbyStores(filters) {
      try {
        const res = await this.fetchShortStores({
          by_establishment: this.landingPage.establishment_id,
          by_category: "online",
          ...filters,
          meta: { pagination: { per_page: 10000 } },
        })

        if (res.data.length === 0) {
          throw new Error("No nearby stores found")
        }

        return res
      } catch (e) {
        this.showErrorToast(
          "Error",
          "Lo sentimos, no encontramos tiendas cercanas en tu código postal actual"
        )
        this.selectedProduct = null

        if (this.storeProducts.length > 0 && this.categories.length > 0) {
          throw e
        }

        if (this.storeProducts.length === 0) {
          await this.getPublicStoreProducts({
            by_distinct_product: true,
            by_establishment: this.landingPage.establishment_id,
          })
        }

        if (this.categories.length === 0) {
          await this.getCategories()
        }

        this.resetAll()
        throw e
      }
    },

    async getPublicStoreProducts(filters, { page = 1 } = {}) {
      const res = await this.fetchPublicStoreProducts({
        ...filters,
        meta: {
          pagination: { page, per_page: this.perPage },
        },
      })

      this.setStoreProducts(res)

      return res
    },
    async handleNoPublicStoreProducts() {
      this.showErrorToast(
        "Error",
        "Lo sentimos, no encontramos productos disponibles"
      )

      this.resetAll()
    },
    async getCategories(filters) {
      try {
        const res = await this.fetchCategories({
          by_active_status: true,
          by_establishment: this.landingPage.establishment_id,
          ...filters,
          meta: { pagination: { page: 1, per_page: 10000 } },
        })

        this.categories = res.data

        return res
      } catch (e) {
        this.handleFetchError(
          e.response.data,
          "Error al cargar categorías de la tienda"
        )
      }
    },

    async handlePostalCodeSubmit(newPostalCode) {
      this.postalCode = newPostalCode
      localStorage.setItem("postal_code", this.postalCode)

      try {
        await this.handleGeneralPostalCodeSubmit()
      } catch (e) {
        await this.getPublicStoreProducts({
          by_distinct_product: true,
          by_establishment: this.landingPage.establishment_id,
        })
        await this.getCategories()

        this.resetAll()
      }
    },

    hideLoading() {
      if (this.appLoading) {
        this.appLoading.style.display = "none"
      }
      this.loading = false
    },
    showLoading() {
      if (this.appLoading) {
        this.appLoading.style.display = "flex"
      }
      this.loading = true
    },

    async handleGeneralPostalCodeSubmit() {
      try {
        this.$swal({
          title: "Cargando tiendas cercanas",
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          },
        })

        this.setCurrentDeliveryOption(this.selectedDeliveryOption)
        localStorage.setItem("delivery_option", this.selectedDeliveryOption)

        const nearbyStoresResponse = await this.getNearbyStores({
          by_delivery_option: this.selectedDeliveryOption,
          by_postal_code:
            this.currentDeliveryOption === "not_applicable"
              ? null
              : this.postalCode,
          with_store_products: true,
        })

        this.setNearbyStores(nearbyStoresResponse)

        this.emptyCart()
        this.setStore(null)
        this.setCurrentStore(null)

        const storeIds = nearbyStoresResponse.data.map((store) => store.id)

        this.has_store = false
        this.selectedStore = null

        this.$bvModal.hide("delivery-options-modal")
        this.$bvModal.hide("ask-postal-code-modal")

        this.toggleFiltering()
        this.clearFilters()
        this.toggleFiltering()

        if (this.selectedProduct) {
          await this.getPublicStoreProducts({
            by_distinct_product: true,
            by_store: storeIds,
          })
          await this.getCategories({
            by_store: storeIds,
          })

          const res = await this.getNearbyStores({
            by_postal_code:
              this.currentDeliveryOption === "not_applicable"
                ? null
                : this.postalCode,
            by_delivery_option: this.currentDeliveryOption,
            by_product: this.selectedProduct.id,
          })

          const uniqueStores = filterUniqueById(res.data)

          this.setAvailableStores(uniqueStores)
          this.$bvModal.show("store-product-options-modal")
        } else if (nearbyStoresResponse.data.length === 1) {
          this.setStore(nearbyStoresResponse.data[0])

          this.$bvModal.hide("delivery-options-modal")
          this.$bvModal.hide("ask-postal-code-modal")

          return
        } else {
          await this.getPublicStoreProducts({
            by_distinct_product: true,
            by_store: storeIds,
          })
          await this.getCategories({
            by_store: storeIds,
          })
        }
      } catch (e) {
        throw e
      } finally {
        this.$bvModal.hide("delivery-options-modal")
        this.$bvModal.hide("ask-postal-code-modal")

        this.$swal.close()
      }
    },

    resetAll() {
      this.has_store = false
      this.postalCode = null

      localStorage.removeItem("postal_code")
      localStorage.removeItem("delivery_option")

      if (this.selectedStore) {
        window.location.reload()
      }

      this.selectedStore = null
      this.setCurrentDeliveryOption(null)
      this.selectedProduct = null

      this.emptyCart()
      this.setCurrentStore(null)
      this.setAvailableStores([])
      this.setStore(null)
      this.setNearbyStores([])
    },
    resetStates() {
      if (localStorage.getItem("delivery_option") === "not_applicable") {
        localStorage.removeItem("delivery_option")
        this.setCurrentDeliveryOption(null)
      }

      this.setCurrentDeliveryOption(null)
      this.emptyCart()
      this.setStore(null)
      this.has_store = false
      this.setCurrentStore(null)
      this.selectedStore = null
      this.selectedProduct = null
    },
    goBack() {
      this.$bvModal.show("delivery-options-modal")
    },

    handleProductDetails(product) {
      const product_attributes = {
        ...product,
        store_products: undefined,
      }

      return {
        ...this.selectedStoreProduct,
        ...product_attributes,
      }
    },

    handleChangePostalCode() {
      this.selectedProduct = null
      this.$bvModal.show("delivery-options-modal")
    },

    handleStoreProductSelection(product, storeProduct) {
      this.selectedStoreProduct = {
        ...storeProduct,
        product_attributes: {
          ...product,
          store_products: undefined,
        },
      }

      this.$bvModal.show("store-product-details-modal")
    },

    handleSearch(query) {
      this.filters.q = query
    },
    setQuery(query) {
      this.filters.q = query
      if (this.stopFiltering) return

      this.handleOnChangeFilters()
    },

    shouldShowDeliveryOptionsModal() {
      return (
        !this.isPostalCodeValid() &&
        !this.store_id &&
        !this.token &&
        this.currentDeliveryOption !== "not_applicable" &&
        !this.store
      )
    },

    async handleAddToCart({
      product,
      storeProduct,
      bestPromotion = null,
      units = 1,
      uniqueId,
      skipReferences = false,
    }) {
      this.selectedProduct = product

      if (this.shouldShowDeliveryOptionsModal()) {
        this.$bvModal.show("delivery-options-modal")
        return
      }

      if (!this.store) {
        const res = await this.getNearbyStores({
          by_postal_code:
            this.currentDeliveryOption === "not_applicable"
              ? null
              : this.postalCode,
          by_delivery_option: this.currentDeliveryOption,
          by_product: product.id,
        })

        const uniqueStores = filterUniqueById(res.data)
        this.setAvailableStores(uniqueStores)

        this.toggleFiltering()
        this.clearFilters()
        this.toggleFiltering()

        this.$bvModal.show("store-product-options-modal")

        return
      } else {
        const newStoreProduct = {
          ...storeProduct,
          product_attributes: {
            ...product,
            store_products: undefined,
          },
          store_attributes: {
            ...this.store,
          },
        }

        if (this.storeHasReferences && !skipReferences) {
          this.$bvModal.show(`book-store-modal-${storeProduct.id}-${uniqueId}`)
          return
        }

        if (!bestPromotion) {
          this.addCartItem({
            ...newStoreProduct,
            units,
          })
          return
        }

        const { needs_to_buy } = bestPromotion

        if (Number(newStoreProduct.units) >= needs_to_buy) {
          this.addCartItem({
            ...newStoreProduct,
            units: parseInt(newStoreProduct.units),
          })
          return
        }

        this.$swal({
          title: "Producto con oferta",
          text: "¿Deseas aplicar la oferta?",
          icon: "warning",
          showDenyButton: true,
          confirmButtonText: "Si, aplicar",
          denyButtonText: "No",
          customClass: {
            confirmButton: "btn btn-primary",
            denyButton: "btn btn-outline-danger mr-1",
          },
          reverseButtons: true,
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.addCartItem({
              ...newStoreProduct,
              units: parseInt(needs_to_buy),
            })
          } else if (result.isDenied) {
            this.addCartItem(newStoreProduct)
          }
        })
      }

      this.selectedProduct = null
    },
    async handlePagination({ page, per_page }) {
      if (this.selectedStore) {
        const res = await this.fetchGroupedStoreProducts({
          by_store: this.store.id,
          by_active_status: true,
          by_category: this.filters.categories || null,
          by_nutritional_info: this.filters.nutriScore || null,
          by_name: this.filters.q || null,
          meta: {
            pagination: {
              per_page: this.perPage,
              page,
            },
          },
        })

        this.setStoreProducts(res)
      } else if (
        this.postalCode ||
        this.currentDeliveryOption === "not_applicable"
      ) {
        await this.getPublicStoreProducts(
          {
            by_store: this.nearbyStoreIds,
            by_category: this.filters.categories || null,
            by_nutritional_info: this.filters.nutriScore || null,
            by_distinct_product: true,
            by_name: this.filters.q || null,
          },
          { page }
        )
      } else {
        await this.getPublicStoreProducts(
          {
            by_distinct_product: true,
            by_category: this.filters.categories || null,
            by_nutritional_info: this.filters.nutriScore || null,
            by_establishment: this.landingPage.establishment_id,
            by_name: this.filters.q || null,
          },
          {
            page,
          }
        )
      }
      window.scrollTo(0, 0)
    },
    handleStoreChange(event) {
      if (!this.previousStore) {
        this.selectedStore = event
        this.previousStore = this.selectedStore
        return
      }

      if (this.selectedStore === event) {
        return
      }

      if (!this.cart.length) {
        this.selectedStore = event
        this.previousStore = this.selectedStore
        return
      }

      this.$swal({
        title: "Cambiando de tienda",
        text: "¿Está seguro de que quiere cambiar de tienda? Tu carro de compras no se guardará.",
        icon: "warning",
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: "Cambiar",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-secondary mr-1",
        },
        reverseButtons: true,
        buttonsStyling: false,
      }).then((res) => {
        if (res.isConfirmed) {
          this.selectedStore = event
          this.previousStore = this.selectedStore
        } else {
          this.$nextTick(() => {
            this.selectedStore = this.previousStore
            this.$refs.storeSelect.$el.value = this.previousStore
          })
        }
      })
    },
  },
  computed: {
    ...mapGetters("landingPage", [
      "store",
      "landingPage",
      "establishment",
      "currentDeliveryOption",
    ]),
    ...mapGetters("storeProducts", [
      "pagination",
      "storeProducts",
      "availableStores",
    ]),
    ...mapGetters("stores", ["nearbyStores", "currentStore"]),
    ...mapGetters("cart", ["cart"]),

    nearbyStoreIds() {
      if (!this.nearbyStores) {
        return []
      }

      return this.nearbyStores.map((store) => store.id)
    },

    storeOptions() {
      if (!this.nearbyStores) {
        return []
      }

      return this.nearbyStores.map((store) => ({
        text: store.name,
        value: store.id,
      }))
    },
  },
}
</script>

<style lang="scss">
.modal-content {
  background-color: #1b243d;

  .modal-body {
    padding: 0rem;
    backdrop-filter: #1b243d;
    z-index: 100;
  }
}

@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.landing__banner {
  margin-top: 10rem;

  @media (min-width: 768px) {
    margin-top: 10rem;
  }

  @media (min-width: 992px) {
    margin-top: 12em;
  }
}

.sidebar__container {
  position: absolute;
  top: 0;
  left: 0;
}

.main__contaner {
  padding-bottom: 4rem;
}

.carousel-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.snap__container {
  position: relative;
  width: 100%;
  scroll-behavior: smooth;
  transition: scroll-left 0.5s;
  display: flex;
  overflow-x: auto;

  scroll-snap-type: x mandatory;

  @media (min-width: 768px) {
    overflow-x: hidden;
  }

  .snap__item {
    flex: 0 0 auto;
    width: 100%;
    scroll-snap-align: center;
    object-fit: cover;
    object-position: center;
    max-height: 250px;

    @media (min-width: 768px) {
      max-height: 400px;
    }
  }
}

.store-select {
  width: 100%;
  z-index: 1000;
}

.snap__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  align-items: center;
  justify-content: center;
  width: 50px;
  background-color: #ccc;
  border-radius: 50%;
  height: 50px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #aaa;
  }

  @media (min-width: 768px) {
    display: flex;
  }
}

#prevBtn {
  left: 10px;
}

#nextBtn {
  right: 10px;
}

.spinner__container {
  padding: 2rem;
}

.products__container {
  display: flex;
  justify-content: space-between;
}

.mt-10 {
  margin-top: 8.4rem;
}

@supports (animation-timeline: view()) {
  @keyframes adjust-searchbar-bg {
    to {
      padding: 10px;
      backdrop-filter: blur(10px);
    }
  }
  .searchbar-container {
    animation: adjust-searchbar-bg linear both;
    animation-timeline: view();
    animation-range: contain;
    padding: 0px;
    background-color: transparent;
    top: 60px;
    z-index: 10;
  }
}
</style>
