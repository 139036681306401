<template>
  <b-pagination
    v-model="pagination.page"
    :total-rows="pagination.total_objects"
    :per-page="pagination.per_page"
    first-number
    last-number
    class="mb-0 mt-1 mt-sm-0"
    prev-class="prev-item"
    next-class="next-item"
    @change="
      (value) => {
        handlePagination({
          page: value,
          per_page: pagination.per_page,
        })
      }
    "
  >
    <template #prev-text>
      <feather-icon icon="ChevronLeftIcon" size="18" />
    </template>
    <template #next-text>
      <b-spinner v-if="loading" small label="Loading" />
      <feather-icon v-else icon="ChevronRightIcon" size="18" />
    </template>
  </b-pagination>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    handlePagination: {
      type: Function,
      default: () => {},
    },
    pagination: {
      type: Object,
      default: () => ({
        page: 1,
        per_page: 10,
        total_objects: 0,
      }),
    },
  },
};
</script>
