<template>
  <b-modal
    id="delivery-addresses-modal"
    title="Direcciones de entrega"
    body-class="p-1"
    hide-footer
    scrollable
  >
    <ul v-if="addressesWithFormattedAddress.length !== 0">
      <li v-for="(address, index) in addressesWithFormattedAddress" :key="index" class="border p-1">
        <div class="whitespace-normal text-break">
          <b v-if="address.formatted_address">
            {{ address.formatted_address }}
          </b>
        </div>

        <div
          class="w-100 d-flex justify-content-center mt-1"
        >

        <b-button
          variant="outline-primary"
          @click="handleSetAddress(address)"
        >
          Seleccionar
        </b-button>
        </div>
      </li>
    </ul>
    <div v-else class="text-center">No se han registrado direcciones</div>
  </b-modal>
</template>

<script>
export default {
  props: {
    addresses: {
      type: Array,
      default: () => [],
    },
    handleAddressSelection: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    addressesWithFormattedAddress() {
      return this.addresses.filter((address) => address.formatted_address)
    }
  },
  methods: {
    handleSetAddress(address) {
      this.handleAddressSelection(address)
      this.$bvModal.hide("delivery-addresses-modal")
    },
  },
}
</script>
