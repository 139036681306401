<template>
  <b-modal
    id="store-product-details-modal"
    title="Descripción del producto"
    class="modal-content"
    no-stacking
    no-close-on-esc
    ok-variant="outline-secondary"
    ok-title="Cerrar"
    ok-only
    scrollable
    @ok="$bvModal.hide(`store-product-details-modal`)"
    size="lg"
  >
    <div
      class="position-relative mx-2"
      v-if="storeProduct && storeProduct.product_attributes"
    >
      <b-row class="my-2">
        <b-col cols="12" md="5" lg="4">
          <div class="product-img-container mb-2 mb-md-0">
            <b-img
              v-if="storeProduct.product_attributes.logo"
              :src="`${storeProduct.product_attributes.logo}`"
              :alt="`Image of ${storeProduct.product_attributes.name}`"
              class="product-img w-100"
              rounded
              fluid
            />
          </div>
        </b-col>

        <b-col cols="12" md="7" lg="8">
          <h4>{{ storeProduct.product_attributes.name }}</h4>

          <b-card-text class="item-company mb-0">
            <p class="mb-0">
              {{ storeProduct.product_attributes.variant }}
            </p>
          </b-card-text>

          <div class="price mt-1">
            <span v-if="storeProduct.range_price">
              ${{ storeProduct.range_price }}
            </span>
            <span v-else>${{ storeProduct.unit_price | money }}</span>
          </div>

          <div
            v-if="
              storeProduct.product_attributes.is_edible &&
              storeProduct.product_attributes.nutritional_info
            "
            class="d-flex align-items-center my-1"
          >
            <div class="ecommerce-details-price d-flex flex-wrap">
              <nutri-score
                :nutritional-info="
                  storeProduct.product_attributes.nutritional_info
                "
              />
            </div>
          </div>

          <div
            class="product-color-option mt-2 pt-0"
            v-if="
              storeProduct.product_attributes.categories_names &&
              storeProduct.product_attributes.categories_names.length > 0
            "
          >
            <h6>Categorias</h6>
            <ul class="list-unstyled m-0">
              <li
                v-for="category in storeProduct.product_attributes
                  .categories_names"
                :key="`category-${category.id}`"
                class="d-inline-block"
              >
                <b-badge class="whitespace-normal">{{ category }}</b-badge>
              </li>
            </ul>
          </div>

          <div v-if="bestPromotion" class="pt-1">
            <b-badge
              v-if="bestPromotion.promo_type !== 'free_product'"
              pill
              :variant="'light-info'"
            >
              {{ "Si compras " + bestPromotion.needs_to_buy }}
              {{
                bestPromotion.needs_to_buy > 1
                  ? "unidades te llevas "
                  : "unidad te llevas "
              }}
            </b-badge>

            <b-badge
              v-if="bestPromotion.promo_type === 'price_discount'"
              pill
              :variant="'light-info'"
              class="ml-50"
            >
              <span>
                {{ bestPromotion.discount + "% de " }}
              </span>
              <span>
                {{ bestPromotion.promo_type | promotionType }}
              </span>
            </b-badge>
            <b-badge
              v-if="
                bestPromotion && bestPromotion.promo_type === 'price_discount'
              "
              pill
              :variant="'light-info'"
              class="ml-50"
            >
              {{ "Ahorras " }}
              ${{
                (bestPromotion.needs_to_buy *
                  storeProduct.unit_price *
                  bestPromotion.discount) /
                100
              }}
              {{ "MXN" }}
            </b-badge>
            <b-badge
              v-if="bestPromotion.promo_type === 'reward_points'"
              pill
              :variant="'light-info'"
              class="ml-50"
            >
              <span>
                {{ bestPromotion.reward_points + "% de " }}
              </span>
              <span>
                {{ bestPromotion.promo_type | promotionType }}
              </span>
            </b-badge>
            <b-badge
              v-if="bestPromotion.promo_type === 'reward_points'"
              pill
              :variant="'light-info'"
              class="mt-50"
            >
              {{ "Equivalente a $" }}
              {{
                (bestPromotion.needs_to_buy *
                  storeProduct.unit_price *
                  bestPromotion.reward_points) /
                100
              }}
              {{ "MXN" }}
            </b-badge>

            <!-- Free product -->
            <b-badge
              v-if="bestPromotion.promo_type === 'free_product'"
              pill
              :variant="'light-info'"
            >
              {{ bestPromotion.needs_to_buy }} {{ "x" }}
              {{ bestPromotion.gets_you }}
            </b-badge>
            <b-badge
              v-if="bestPromotion.promo_type === 'free_product'"
              pill
              :variant="'light-info'"
              class="ml-50"
            >
              {{ "Ahorras" }}
              {{
                (bestPromotion.needs_to_buy - bestPromotion.gets_you) *
                storeProduct.unit_price
              }}
              {{ "MXN" }}
            </b-badge>
          </div>

          <div
            v-if="
              storeProduct &&
              storeProduct.product_attributes &&
              storeProduct.product_attributes.sku
            "
          >
            <hr />
            <div
              class="
                d-flex
                align-items-center
                courier-prime-regular
                h3
                text-white
              "
            >
              <i class="fas fa-barcode mr-1" />
              {{ storeProduct.product_attributes.sku }}
            </div>
          </div>

          <div v-if="storeProduct.description" class="">
            <hr />
            <ul class="mt-2 description-area">
              <li
                v-for="(item, index) in getDescriptionArray(
                  storeProduct.description
                )"
                class="mb-50 text-break list-inside"
                :key="index"
              >
                {{ capitalize(item) }}
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
// Components
import NutriScore from "@/@core/components/NutriScore.vue"

// Utils
import { capitalize, getDescriptionArray } from "@core/utils/text-utils"
import { getBestPromotion } from "@/@core/utils/store-product-utils";

export default {
  props: {
    storeProduct: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    NutriScore,
  },
  computed: {
    bestPromotion() {
      if (!this.storeProduct || !this.storeProduct.promotion_attributes) return

      return this.getBestPromotion(this.storeProduct)
    },
  },
  methods: {
    getDescriptionArray,
    getBestPromotion,
    capitalize,
  },
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.description-area {
  max-height: 200px;
  overflow-y: auto;
  scrollbar-color: #888 #555;
  scrollbar-width: thin;
  padding-right: 0.5rem;
}

.product-img-container {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  background-color: rgba(white, 0.1);
  width: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.price {
  font-size: 1.6rem;
  font-weight: 600;
  color: #5e72e4;
}

.courier-prime-regular {
  font-family: "Courier Prime", monospace;
  font-weight: 400;
  font-style: normal;
}
</style>
