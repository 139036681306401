<template>
  <b-sidebar
    id="store-product-filters"
    title="Filtros"
    shadow
    backdrop
    lazy
    hide-header
    body-class="p-2"
  >
    <!-- <div class="ratings mb-3">
      <h6 class="filter-title">Valor nutricional</h6>
      <div
        v-for="score in nutriScore"
        :key="score"
        @click="handleNutriScore(score)"
        class="d-flex justify-content-between w-100 mb-1"
      >
        <nutri-score :nutritionalInfo="score" />
        <i v-if="filters.nutriScore.includes(score)" class="fas fa-circle" />
      </div>
    </div> -->

    <!-- Categories -->
    <div class="product-categories">
      <h6 class="filter-title mt-0 mb-1 text-xl">Categorías</h6>
      <b-form-checkbox-group
        v-model="filters.categories"
        class="categories-radio-group"
        stacked
        :options="displayedCategories"
        text-field="name"
        value-field="id"
        @change="onChange"
      />
      <b-button v-if="showViewAllButton" variant="link" @click="toggleViewAll">
        {{ viewAll ? "Ver menos" : "Ver todas" }}
      </b-button>
    </div>
  </b-sidebar>
</template>

<script>
import NutriScore from "@/@core/components/NutriScore.vue"

export default {
  components: {
    NutriScore,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      nutriScore: ["a", "b", "c", "d", "e"],
      viewAll: false,
      viewAllStores: false,
    }
  },
  methods: {
    handleNutriScore(score) {
      if (!this.filters.nutriScore.includes(score)) {
        this.filters.nutriScore.push(score)
      } else {
        this.filters.nutriScore.splice(
          this.filters.nutriScore.indexOf(score),
          1
        )
      }
    },
    toggleViewAll() {
      this.viewAll = !this.viewAll
    },
  },
  computed: {
    displayedCategories() {
      return this.viewAll ? this.categories : this.categories.slice(0, 10)
    },
    showViewAllButton() {
      return this.categories.length > 10
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-slider.scss";
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>
