<template>
  <b-button
    :variant="buttonVariant"
    :size="buttonSize"
    class="payment__button w-100 h-100"
    :disabled.sync="loading"
    @click="$emit('click')"
  >
    <b-img :src="imageSrc" :width="imageWidth" />
    <b-spinner v-if="loading" large />
    <span class="button-text p-1">{{ buttonText }}</span>
  </b-button>
</template>

<script>
export default {
  props: {
    buttonVariant: {
      type: String,
      default: "outline-primary",
    },
    buttonSize: {
      type: String,
      default: "sm",
    },
    imageSrc: {
      type: String,
      required: true,
    },
    imageWidth: {
      type: Number,
      default: 80,
    },
    buttonText: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.payment__button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  min-height: 120px;
  border-radius: 10px;
  font-size: 1rem !important;
  font-weight: 500;
  transition: transform 0.2s ease;
  cursor: pointer;

  img {
    width: 70px;
  }

  &:hover {
    transform: scale(1.05);
  }
}

@media (min-width: 480px) {
  .payment-button {
    font-size: 1.2rem !important;
  }
}

@media (min-width: 768px) {
  .payment-button {
    img {
      max-width: min-content;
    }
  }
}
</style>
