<template>
  <b-modal
    :id="`bascula-modal-${product.id}-${uniqueId}`"
    no-fade
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    @hidden="reset"
    hide-footer
  >
    <div>
      <div class="text-4xl text-right scaleDisplay px-1">
        {{ Number(scaleValue) }}
      </div>
      <div class="text-right px-1">Inventario: {{ product.inventory }}</div>
      <div class="numpad">
        <div
          v-for="n in 9"
          :key="`btn-${n}`"
          class="btn"
          @click="typeNum(`${n}`)"
        >
          {{ n }}
        </div>
        <div class="btn" @click="typeDot('.')">.</div>
        <div class="btn" @click="typeNum('0')">0</div>
        <div class="btn" @click="delNum()">
          <i class="fas fa-backspace" />
        </div>
      </div>

      <div class="d-flex justify-content-end mt-2 p-2 border">
        <b-button variant="outline-secondary" class="mr-2" @click="reset">
          Cancelar
        </b-button>
        <b-button variant="primary" @click="handleOk"> Aceptar </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      default: null,
    },
    uniqueId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      scaleValue: "",
    }
  },
  computed: {
    isWeighted() {
      return this.product?.product_attributes?.is_weighted
    },
  },
  methods: {
    handleOk() {
      if (
        Number(this.product.inventory) < Number(this.scaleValue) &&
        this.product.has_inventory
      ) {
        this.$toast.error("No hay suficiente inventario para esta operación")
        this.$bvModal.hide(`bascula-modal-${this.product.id}-${this.uniqueId}`)
        return
      }

      if (this.scaleValue || Number(this.scaleValue) > 0) {
        this.$emit("edit-units", Number(this.scaleValue).toFixed(3))
      }

      this.$bvModal.hide(`bascula-modal-${this.product.id}-${this.uniqueId}`)
    },

    typeNum(val) {
      const prevVal = this.scaleValue

      if (this.scaleValue.includes(".")) {
        const [integerPart, decimalPart] = this.scaleValue.split(".")

        if (decimalPart.length < 3) {
          this.scaleValue += val
        } else {
          this.scaleValue = `${integerPart}.${decimalPart.slice(0, 2)}${val}`
        }
      } else if (val === "0" && this.scaleValue === "") {
        this.scaleValue = "0."
      } else {
        this.scaleValue += val
      }

      if (
        Number(this.scaleValue) > Number(this.product.inventory) &&
        this.product.has_inventory
      ) {
        this.scaleValue = prevVal
        this.$toast.error("No hay suficiente inventario para esta operación")
      }
    },

    typeDot() {
      if (!this.isWeighted) {
        return
      }

      if (!this.scaleValue.includes(".")) {
        if (this.scaleValue === "") {
          this.scaleValue += "0."
        } else {
          this.scaleValue += "."
        }
      }
    },

    delNum() {
      this.scaleValue = this.scaleValue.slice(0, -1)
    },

    reset() {
      this.scaleValue = ""
      this.$bvModal.hide(`bascula-modal-${this.product.id}-${this.uniqueId}`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/colors/palette-variables.scss";

.numpad {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .btn {
    width: 30%;
    font-size: 2rem;
    font-weight: 800;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(white, 0.02);
    border-radius: 10px;

    &:hover {
      background: $primary;
      color: white;
    }
  }
}
.scaleDisplay {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // background: rgba(white, 0.02);
  // border-radius: 10px;
  // padding: .5rem;
  // margin-bottom: 1rem;
  overflow: hidden;
}
</style>
