<template>
  <b-modal
    id="delivery-options-modal"
    title="Selecciona una opción de entrega"
    hide-footer
    no-stacking
    scrollable
    @hidden="deliveryOption = null"
  >
    <div class="delivery__options__container">
      <b-card
        v-for="option in deliveryOptions"
        :key="option.id"
        class="delivery__option mb-0 cursor-pointer shadow-sm"
        body-class="p-4"
        :header="option.name"
        :header-bg-variant="option.bgVariant"
        :header-text-variant="option.id === 1 ? 'white' : 'white'"
        @click="deliveryOption = option.value"
      >
        <feather-icon
          :icon="option.icon"
          class="d-block mx-auto my-1"
          size="60"
        />
        <b-card-text>{{ option.description }}</b-card-text>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      deliveryOption: null,
      deliveryOptions: [
        {
          id: 1,
          name: "Recoger en tienda",
          value: "pickup",
          icon: "ShoppingBagIcon",
          description: "Recoge tu pedido en la tienda más cercana",
          bgVariant: "primary",
        },
        {
          id: 2,
          name: "Entrega a domicilio",
          value: "delivery",
          icon: "HomeIcon",
          description: "Recibe tu pedido en la comodidad de tu hogar",
          bgVariant: "success",
        },
        {
          id: 3,
          name: "No aplica",
          value: "not_applicable",
          icon: "XCircleIcon",
          description: "No aplica para esta compra",
          bgVariant: "danger",
        },
      ],
    }
  },
  watch: {
    deliveryOption(value) {
      if (value) {
        this.$emit("update:deliveryOption", value)
        this.deliveryOption = value
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.delivery__options__container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  padding: 1rem;

  .delivery__option {
    border-radius: 0.5rem;
    background-color: rgba(white, 0.1);
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1) !important;
      transform: translateY(-5px);
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}
</style>
