<template>
  <b-modal
    id="store-product-options-modal"
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    hide-footer
    centered
  >
    <div
      v-if="!availableStores.length"
      class="d-flex flex-column align-items-center py-2 justify-content-center"
    >
      <h3 class="text-center">Espere un momento...</h3>
      <b-spinner label="Cargando tiendas" type="grow" variant="primary" />
    </div>

    <div v-else>
      <form-wizard
        :title="null"
        :subtitle="null"
        class="wizard-vertical"
        step-size="sm"
        layout="horizontal"
        @on-complete="handleSubmit"
      >
        <tab-content
          title="Tiendas disponibles"
          :before-change="validateStoreSelection"
        >
          <div class="pb-2">
            <GmapMap
              v-if="availableStores.length"
              :center="center"
              :zoom="zoom"
              :style="`width: ${width}; height: ${height}`"
              :options="mapOptions"
            >
              <GmapMarker
                v-for="store in availableStores"
                v-if="
                  store.address_attributes &&
                  store.address_attributes.lat &&
                  store.address_attributes.lng
                "
                :key="store.id"
                :position="{
                  lat: parseFloat(store.address_attributes.lat),
                  lng: parseFloat(store.address_attributes.lng),
                }"
                :clickable="true"
                :draggable="false"
                :label="{
                  text: store.name,
                  color: 'black',
                  fontSize: '14px',
                  fontWeight: 'bold',
                }"
              >
              </GmapMarker>
            </GmapMap>
          </div>
          <b-list-group class="stores__list">
            <b-list-group-item
              v-for="store in availableStores"
              :key="store.id"
              @click="handleStoreSelection(store)"
              class="d-flex align-items-center cursor-pointer"
              :active="selectedStore && selectedStore.id === store.id"
            >
              <b-avatar
                v-if="!store.logo"
                :src="store.logo"
                :alt="`Logo de ${store.name}`"
                size="2.5rem"
              />
              <b-avatar v-else :text="store.name.charAt(0)" size="2.5rem" />

              <h5 class="p-0 m-0 ml-1">{{ store.name }}</h5>
            </b-list-group-item>
          </b-list-group>
        </tab-content>
        <tab-content title="Productos disponibles">
          <div class="my-2" v-if="product && selectedStoreProduct">
            <StoreProductItem
              :product="product"
              :storeProduct="selectedStoreProduct"
              @update:selectedStoreProduct="selectedStoreProduct = $event"
            />
          </div>
        </tab-content>

        <b-button variant="outline-secondary" slot="prev"> Regresar </b-button>

        <b-button variant="primary" slot="next"> Siguiente </b-button>

        <b-button
          variant="primary"
          slot="finish"
          :disabled="
            !selectedStoreProduct || selectedStoreProduct.inventory === '0.0'
          "
        >
          Agregar al carrito
        </b-button>
      </form-wizard>
    </div>
  </b-modal>
</template>

<script>
// Directives
import { mapActions, mapGetters, mapMutations } from "vuex"

import { FormWizard, TabContent } from "vue-form-wizard"

// Components
import StoreProductItem from "./StoreProductItem.vue"

// Mixins
import cartMixin from "@/@core/mixins/cartMixin"
import messagesMixin from "@/@core/mixins/messagesMixin"

// Utils
import { getBestPromotion } from "@/@core/utils/store-product-utils"

// Styles
import "vue-form-wizard/dist/vue-form-wizard.min.css"
import { needReferences } from "../../utils/store-product-utils"

export default {
  components: {
    FormWizard,
    TabContent,

    StoreProductItem,
  },
  data() {
    return {
      product: null,
      selectedStore: null,
      selectedStoreProduct: null,
      center: { lat: 23.6345, lng: -102.5528 },
      zoom: 5,
      width: "100%",
      height: "400px",
      mapOptions: {
        restriction: {
          latLngBounds: {
            north: 32.718,
            south: 14.532,
            west: -118.438,
            east: -86.708,
          },
          strictBounds: true,
        },
      },
    }
  },
  mixins: [cartMixin, messagesMixin],
  watch: {
    availableStores: {
      handler(newVal) {
        if (newVal.length > 0) {
          if (this.currentDeliveryOption === "pickup") {
            this.center = {
              lat: parseFloat(newVal[0].address_attributes.lat),
              lng: parseFloat(newVal[0].address_attributes.lng),
            }
            this.zoom = 15
          }
        }
      },
      immediate: true,
    },
  },
  props: {
    selectedProduct: {
      type: Object,
      default: () => {},
    },
    clearFilters: {
      type: Function,
      default: () => {},
    },
    toggleFiltering: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("storeProducts", [
      "availableStoreProducts",
      "availableStores",
    ]),
    ...mapGetters("landingPage", ["currentDeliveryOption"]),
    ...mapGetters("stores", ["currentStore", "storeReferences"]),
  },
  methods: {
    ...mapActions("storeProducts", ["fetchGroupedStoreProducts"]),
    ...mapActions("cart", ["addCartItem"]),

    ...mapMutations("landingPage", ["setStore"]),
    ...mapMutations("storeProducts", [
      "setStoreProducts",
      "setAvailableStores",
    ]),
    ...mapMutations("stores", ["setNearbyStores"]),

    getBestPromotion,

    handleStoreSelection(store) {
      this.selectedStore = store
    },

    resetModal() {
      this.selectedStore = null
      this.selectedStoreProduct = null
      this.setAvailableStores([])
    },

    cleanUpAndCloseModal() {
      this.setNearbyStores(this.availableStores)
      this.setStore(this.selectedStore)
      this.selectedStore = null
      this.selectedStoreProduct = null
      this.$bvModal.hide("store-product-options-modal")
    },

    async handleSubmit() {
      if (
        !this.selectedStoreProduct ||
        this.selectedStoreProduct.inventory === "0.0"
      ) {
        this.showErrorToast(
          "Error",
          "Lo sentimos, este producto está agotado",
          "CoffeeIcon"
        )

        return
      }

      const newStoreProduct = {
        ...this.selectedStoreProduct,
        product_attributes: {
          ...this.product,
          store_products: undefined,
        },
        store_attributes: {
          ...this.store,
        },
      }

      const bestPromotion = this.getBestPromotion(newStoreProduct)
      const isReferenceNeed = needReferences(this.selectedStore)

      if (isReferenceNeed) {
        this.warningToast(
          "Advertencia",
          "No se puede agregar el producto porque requiere referencias. Por favor, vuelve a seleccionar el producto"
        )

        this.cleanUpAndCloseModal()
      } else if (
        !bestPromotion ||
        (bestPromotion && Number(newStoreProduct.units)) >=
          Number(bestPromotion.needs_to_buy)
      ) {
        this.addCartItem(newStoreProduct)
        this.cleanUpAndCloseModal()
      } else {
        this.$swal({
          title: "Producto con oferta",
          text: "¿Deseas aplicar la oferta?",
          icon: "warning",
          showDenyButton: true,
          confirmButtonText: "Si, aplicar",
          denyButtonText: "No",
          customClass: {
            confirmButton: "btn btn-primary",
            denyButton: "btn btn-outline-danger mr-1",
          },
          reverseButtons: true,
          buttonsStyling: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        })
          .then((result) => {
            if (result.isConfirmed) {
              const { needs_to_buy } = bestPromotion
              this.addCartItem({
                ...newStoreProduct,
                units: needs_to_buy,
              })
            } else if (result.isDenied) {
              this.addCartItem(newStoreProduct)
            }
          })
          .finally(() => {
            this.cleanUpAndCloseModal()
          })
      }
    },

    async validateStoreSelection() {
      if (!this.selectedStore) {
        this.showErrorToast("Error", "Selecciona una tienda", "CoffeeIcon")
        return false
      }

      this.$swal({
        title: "Cargando productos...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          this.$swal.showLoading()
        },
      })

      try {
        const res = await this.fetchGroupedStoreProducts({
          by_store: this.selectedStore.id,
          by_product: this.selectedProduct.id,
          by_active_status: true,
          meta: {
            pagination: {
              per_page: 10000,
            },
          },
        })

        if (!res.data.length) {
          this.showErrorToast(
            "Error",
            "Lo sentimos, la tienda no tiene productos disponibles",
            "CoffeeIcon"
          )
          this.$bvModal.hide("store-product-options-modal")
          return false
        }

        this.product = res.data[0]
        this.selectedStoreProduct = res.data[0].store_products[0]

        return true
      } catch {
        this.showErrorToast(
          "Error",
          "Lo sentimos, no encontramos productos disponibles",
          "CoffeeIcon"
        )
        this.$bvModal.hide("store-product-options-modal")
      } finally {
        this.toggleFiltering()
        this.$swal.close()
      }
    },
  },
}
</script>

<styles lang="scss">
.custom-control-primary {
  .custom-control-label {
    font-size: 1rem;
  }
}

.vue-form-wizard {
  .wizard-header {
    display: none;
  }
}
</styles>

<style lang="scss" scoped>
.stores__list {
  max-height: 400px;
  overflow-y: auto;
}
</style>
