var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"modal-content",attrs:{"id":"store-product-details-modal","title":"Descripción del producto","no-stacking":"","no-close-on-esc":"","ok-variant":"outline-secondary","ok-title":"Cerrar","ok-only":"","scrollable":"","size":"lg"},on:{"ok":function($event){return _vm.$bvModal.hide("store-product-details-modal")}}},[(_vm.storeProduct && _vm.storeProduct.product_attributes)?_c('div',{staticClass:"position-relative mx-2"},[_c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"cols":"12","md":"5","lg":"4"}},[_c('div',{staticClass:"product-img-container mb-2 mb-md-0"},[(_vm.storeProduct.product_attributes.logo)?_c('b-img',{staticClass:"product-img w-100",attrs:{"src":("" + (_vm.storeProduct.product_attributes.logo)),"alt":("Image of " + (_vm.storeProduct.product_attributes.name)),"rounded":"","fluid":""}}):_vm._e()],1)]),_c('b-col',{attrs:{"cols":"12","md":"7","lg":"8"}},[_c('h4',[_vm._v(_vm._s(_vm.storeProduct.product_attributes.name))]),_c('b-card-text',{staticClass:"item-company mb-0"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.storeProduct.product_attributes.variant)+" ")])]),_c('div',{staticClass:"price mt-1"},[(_vm.storeProduct.range_price)?_c('span',[_vm._v(" $"+_vm._s(_vm.storeProduct.range_price)+" ")]):_c('span',[_vm._v("$"+_vm._s(_vm._f("money")(_vm.storeProduct.unit_price)))])]),(
            _vm.storeProduct.product_attributes.is_edible &&
            _vm.storeProduct.product_attributes.nutritional_info
          )?_c('div',{staticClass:"d-flex align-items-center my-1"},[_c('div',{staticClass:"ecommerce-details-price d-flex flex-wrap"},[_c('nutri-score',{attrs:{"nutritional-info":_vm.storeProduct.product_attributes.nutritional_info}})],1)]):_vm._e(),(
            _vm.storeProduct.product_attributes.categories_names &&
            _vm.storeProduct.product_attributes.categories_names.length > 0
          )?_c('div',{staticClass:"product-color-option mt-2 pt-0"},[_c('h6',[_vm._v("Categorias")]),_c('ul',{staticClass:"list-unstyled m-0"},_vm._l((_vm.storeProduct.product_attributes
                .categories_names),function(category){return _c('li',{key:("category-" + (category.id)),staticClass:"d-inline-block"},[_c('b-badge',{staticClass:"whitespace-normal"},[_vm._v(_vm._s(category))])],1)}),0)]):_vm._e(),(_vm.bestPromotion)?_c('div',{staticClass:"pt-1"},[(_vm.bestPromotion.promo_type !== 'free_product')?_c('b-badge',{attrs:{"pill":"","variant":'light-info'}},[_vm._v(" "+_vm._s("Si compras " + _vm.bestPromotion.needs_to_buy)+" "+_vm._s(_vm.bestPromotion.needs_to_buy > 1 ? "unidades te llevas " : "unidad te llevas ")+" ")]):_vm._e(),(_vm.bestPromotion.promo_type === 'price_discount')?_c('b-badge',{staticClass:"ml-50",attrs:{"pill":"","variant":'light-info'}},[_c('span',[_vm._v(" "+_vm._s(_vm.bestPromotion.discount + "% de ")+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("promotionType")(_vm.bestPromotion.promo_type))+" ")])]):_vm._e(),(
              _vm.bestPromotion && _vm.bestPromotion.promo_type === 'price_discount'
            )?_c('b-badge',{staticClass:"ml-50",attrs:{"pill":"","variant":'light-info'}},[_vm._v(" "+_vm._s("Ahorras ")+" $"+_vm._s((_vm.bestPromotion.needs_to_buy * _vm.storeProduct.unit_price * _vm.bestPromotion.discount) / 100)+" "+_vm._s("MXN")+" ")]):_vm._e(),(_vm.bestPromotion.promo_type === 'reward_points')?_c('b-badge',{staticClass:"ml-50",attrs:{"pill":"","variant":'light-info'}},[_c('span',[_vm._v(" "+_vm._s(_vm.bestPromotion.reward_points + "% de ")+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("promotionType")(_vm.bestPromotion.promo_type))+" ")])]):_vm._e(),(_vm.bestPromotion.promo_type === 'reward_points')?_c('b-badge',{staticClass:"mt-50",attrs:{"pill":"","variant":'light-info'}},[_vm._v(" "+_vm._s("Equivalente a $")+" "+_vm._s((_vm.bestPromotion.needs_to_buy * _vm.storeProduct.unit_price * _vm.bestPromotion.reward_points) / 100)+" "+_vm._s("MXN")+" ")]):_vm._e(),(_vm.bestPromotion.promo_type === 'free_product')?_c('b-badge',{attrs:{"pill":"","variant":'light-info'}},[_vm._v(" "+_vm._s(_vm.bestPromotion.needs_to_buy)+" "+_vm._s("x")+" "+_vm._s(_vm.bestPromotion.gets_you)+" ")]):_vm._e(),(_vm.bestPromotion.promo_type === 'free_product')?_c('b-badge',{staticClass:"ml-50",attrs:{"pill":"","variant":'light-info'}},[_vm._v(" "+_vm._s("Ahorras")+" "+_vm._s((_vm.bestPromotion.needs_to_buy - _vm.bestPromotion.gets_you) * _vm.storeProduct.unit_price)+" "+_vm._s("MXN")+" ")]):_vm._e()],1):_vm._e(),(
            _vm.storeProduct &&
            _vm.storeProduct.product_attributes &&
            _vm.storeProduct.product_attributes.sku
          )?_c('div',[_c('hr'),_c('div',{staticClass:"\n              d-flex\n              align-items-center\n              courier-prime-regular\n              h3\n              text-white\n            "},[_c('i',{staticClass:"fas fa-barcode mr-1"}),_vm._v(" "+_vm._s(_vm.storeProduct.product_attributes.sku)+" ")])]):_vm._e(),(_vm.storeProduct.description)?_c('div',{},[_c('hr'),_c('ul',{staticClass:"mt-2 description-area"},_vm._l((_vm.getDescriptionArray(
                _vm.storeProduct.description
              )),function(item,index){return _c('li',{key:index,staticClass:"mb-50 text-break list-inside"},[_vm._v(" "+_vm._s(_vm.capitalize(item))+" ")])}),0)]):_vm._e()],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }