<template>
  <div>
    <section class="products__grid">
      <component
        v-for="product in products"
        :is="withVariants ? 'StoreProductVariants' : 'StoreProduct'"
        :key="product.id"
        :product="product"
        :addToCart="handleAddToCart"
        :handleStoreProductSelection="handleStoreProductSelection"
        class="item"
      />
    </section>
    <b-card v-if="!products.length" class="w-100 text-center">
      No hay resultados
    </b-card>
  </div>
</template>

<script>
// Components
import StoreProduct from "./StoreProduct.vue"
import StoreProductVariants from "./StoreProductVariants.vue"

export default {
  components: {
    StoreProduct,
    StoreProductVariants,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    withVariants: {
      type: Boolean,
      default: false,
    },
    handleAddToCart: {
      type: Function,
      required: true,
    },
    handleStoreProductSelection: {
      type: Function,
      default: () => {},
    },
  },
}
</script>

<style>
@supports (animation-timeline: view()) {
  @keyframes animate-in {
    entry 0% {
      opacity: 0;
      transform: translateY(100%);
    }
    entry 100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes animate-out {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-100%);
    }
  }

  .product__card {
    animation: linear animate-in;
    animation-timeline: view();
  }
}
</style>

<style lang="scss">
@keyframes appear {
  from {
    opacity: 0;
    scale: 0.9;
    translate: 0 50px;
  }

  to {
    opacity: 1;
    scale: 1;
    translate: 0 0;
  }
}

.products__grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  width: 100%;
  counter-reset: index;

  .item {
    animation: appear linear;
    animation-timeline: view();
    animation-range: entry 0% cover 25%;
  }

  .product__card {
    display: flex;
    flex-direction: row;
    padding: 1rem 16px;
    counter-increment: index;
    margin-bottom: 0;
    cursor: pointer;

    .btn__cart__small {
      display: block;
      border-radius: 50%;
      right: 20px;
      bottom: 80px;
      z-index: 1;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .product__image__container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      border-radius: 0.5rem;
      background-color: rgba(white, 0.1);
      aspect-ratio: 1 / 1;

      @media screen and (max-width: 579px) {
        margin-right: 1rem;
        height: 150px;
      }

      .product__image {
        transition: transform 0.2s ease-in-out;
        width: 100%;
        @media screen and (max-width: 579px) {
          max-width: 150px;
        }
      }
    }

    .product__details {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 1rem 0.1rem;
      word-wrap: break-word !important;
      word-break: break-word !important;
    }

    .product__buttons {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;
      text-align: center;

      .btn__text {
        font-size: 0.8rem;
      }

      .btn__cart {
        width: 100%;
        display: none;
      }
    }
  }

  @media (min-width: 350px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 580px) {
    grid-template-columns: repeat(2, 1fr);

    .product__card {
      flex-direction: column;

      .product__buttons {
        justify-content: center;
      }
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);

    .product__card {
      .product__buttons {
        .btn__text {
          font-size: 1rem;
        }

        .btn__cart {
          display: block;
        }
      }
      .btn__cart__small {
        display: none;
      }
    }
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1536px) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.item-price {
  font-size: 1.3rem;
}
</style>
