<template>
  <b-carousel
    v-if="
      landingPage && landingPage.images && landingPage.images.length > 0
    "
    id="carousel-1"
    :interval="2000"
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
    controls
    indicators
  >
    <b-carousel-slide
      v-for="(image, index) in landingPage.images"
      :key="index"
      :img-src="image.url"
    >
      <template #img>
        <img
          class="banner-img"
          :src="image.url"
          alt="image slot"
        />
      </template>
    </b-carousel-slide>
  </b-carousel>

</template>

<script>
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      slide: 0,
      sliding: null,
    }
  },
  computed: {
    ...mapGetters("landingPage", ["landingPage"]),
  },
  methods: {
    onSlideEnd(slide) {
      this.sliding = false
    },
    onSlideStart(slide) {
      this.sliding = true
    },
  },
}
</script>

<style lang="scss" scoped>
#carousel-1 {
  border-radius: 1rem;
  overflow: hidden;
}

.banner-img {
  height: auto;
  width: 100%;
  object-fit: cover;
}

</style>